
.childrenContainer {
  transition: all 0.3s ease-in-out;
    margin-top: 70px;
  margin-bottom: 20px;
  width: 100%; 
  overflow: hidden;

  &.sidebarClosed {
    margin-left: 64px; 
  }
}
