/* SubCategory.module.css */
.container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.header {
  color: #00c5b9;
}

.imageContainer {
  position: relative;
  border-radius: 8px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  height: 200px;
}

.imagePreview {
  height: 200px;
  width: 200px;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.descriptionContainer {
  margin-top: 20px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  min-height: 200px;
}

.htmlPreview {
  text-align: justify !important;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-family: monospace;
}

.uploadButton {
  margin-top: 16px;
}

.fileInfo {
  margin-top: 8px;
  color: #6a1b9a;
}

.submitButton {
  margin-top: 16px;
}

.loadingSpinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.editIcon {
  margin-right: 8px;
  color: #00c5b9 !important;
}

.deleteIcon {
  color: #00c5b9 !important;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  box-shadow: 24px;
  padding: 16px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogActions {
  padding: 16px;
  justify-content: space-between;
}

.backButton {
  margin-right: 12px;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.subCategoryName {
  color: #00c5b9;
  font-size: 2rem;
}

.tabsContainer {
  padding: 20px;
  overflow-y: scroll;
  border-radius: 8px;
}

.tabStyle {
  text-transform: none;
  min-width: 100px;
}

.activeTab {
  color: #1976d2;
}

.inactiveTab {
  color: #000;
}

.modalTitle {
  color: #00c5b9;
  text-align: center;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 8px;
  z-index: 999999 !important;
}

.descriptionWrapper {
  height: max-content;
}
