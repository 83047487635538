.h1 {
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 56px !important;
}
.h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.body1 {
  font-size: 18px;
  font-weight: 800;
  line-height: 32px;
}
.body2 {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}
.body3 {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.smallText {
}

.labelText {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}
