.imageBox {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.img {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 4px;
}

.avatar {
    margin-right: 2rem;
}

.editButton {
    margin-right: 8px;
    color: #00C5B9 !important;
}