/* Category.module.css */

/* Hide file input elements */
.fileInput {
    display: none;
}

/* Style for edit buttons */
.editButton {
    margin-right: 8px;
    /* Changed from string to number */
    color: #00C5B9 !important;
    /* Removed quotes around color */
}

/* Dialog margin */
.dialog {
    margin-top: 40px;
    /* Ensures dialog has spacing from the top */
}

/* Primary color styling */
.primaryColor {
    color: #00C5B9 !important;
    /* Consistent branding color */
}

/* Label color styling */
.label {
    color: #00C5B9;
    /* Consistent label color */
}

/* Dropdown styling */
.selectdropdown {
    z-index: 9999;
    /* Ensures dropdown appears above other elements */
    margin-top: 6px;
    /* Adds spacing above dropdown */
}



/* Styling for category images */
.categoryImage {
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    border-radius: 4px;
    /* Rounded corners */
    margin-top: 10px;
    /* Spacing above */
    margin-bottom: 10px;
    /* Spacing below */
    border: 2px dashed #00C5B9;
    /* Dashed border for images */
    display: flex;
    /* Flexbox for centering */
    align-items: center;
    /* Centers items vertically */
    justify-content: center;
    /* Centers items horizontally */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition: background-color 0.3s;
    /* Smooth transition for background color */
}

/* Hover effect for category images */
.categoryImage:hover {
    background-color: rgba(0, 197, 185, 0.1);
    /* Light hover effect */
}

/* Upload label styling */
.uploadLabel {
    display: block;
    /* Block-level element for better layout */
}

/* Upload text styling */
.uploadText {
    color: #00C5B9;
    /* Consistent upload text color */
}

/* Styles for uploaded images */
.uploadedImg {
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    object-fit: cover;
    /* Maintains aspect ratio */
}



/* Error text styling */
.errorText {
    margin-top: 5px;
    /* Spacing above error text */
}