#richTextContainer {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  #richTextContainer > div:first-child {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  
  #richTextContainer > div:last-child {
    border: none;
  }

  .errorText {
    color: #f44336; /* Lighter red color for error text */
    font-size: 12px; /* Adjust font size */
    margin-top: 5px; /* Add some spacing above the error message */
    font-weight: bold; /* Optional: make the error text bold */
  }