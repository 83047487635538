.authMainConatiner {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, #00c1bc 0%, #197ba0 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
