.formContainer {
  background-color: white;
  padding: 45px;
  border-radius: 2;
}
.signInBtnWrapper {
  margin-top: 10px;
    .btnTextWrapper{
        color: white;
    }
}
.textfield {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.forgot{
 margin-top: 10px;
}