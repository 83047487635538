.container {
    padding: 16px;
    /* equivalent to Box p={2} */
}

.primaryColor {
    color: #00C5B9 !important;
}

.button {
    color: #00C5B9 !important;
    margin-right: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    /* equivalent to mb={2} */
}

.headerTitle {
    color: #00C5B9;
    font-size: 1.5rem;
    /* Adjust size as needed */
}

.buttonContainer {
    display: flex;
    align-items: center;
    gap: 16px;
    /* equivalent to gap={2} */
}

.searchField {
    width: 200px;
    /* equivalent to Box width={200} */
}

.uploadButton {
    width: 200px;
    /* equivalent to Box width={200} */
}

.createButton {
    width: 100px;
    /* equivalent to Box width={100} */
}

.businessProfileContainer {
    padding: 24px;
    /* equivalent to Box p={3} */
    max-width: 1200px;
    margin: 40px auto;
    /* equivalent to mx: "auto", mt: "40px" */
}

.backButton {
    margin-right: 12px;
    cursor: pointer;
}

.profileTitle {
    color: #00C5B9;
    margin-top: 16px;
    /* equivalent to marginTop={2} */
}

.galleryImage {
    max-width: "200px";
    max-height: "200px";
    margin-top: "10px";
    object-fit: "cover";
}

.gallerybanner {
    width: "100%";
    height: 200;
    border-radius: 2;
    border: "2px dashed #00C5B9";
    display: "flex";
    justify-content: "center";
    align-items: "center";
    cursor: "pointer";
    overflow: "hidden";
}

.gallerybannerindividual {
    width: "100%";
    height: "100%";
    object-fit: "cover";
}

.galleryindividual {
    display: none;
}

.card {
    margin-bottom: 3;
    border-radius: 2;
    overflow: "hidden";
}

.cardMedia {
    object-fit: "cover";
    border-radius: 2;
}

.cardMediaMain {
    width: 100;
    height: 100;
    border-radius: "50%";
    margin-right: 2;
    border: "2px solid #ddd";
}

.timing {
    align-self: "start";
    display: "flex";
    color: " #197BA0", ;
}

.timingBox {
    margin-top: "20px";
    margin-left: "40px";
    margin-right: "40px";
}

.addTiming {
    margin-top: "4px";
    font-size: "14px";
    line-height: "24px";
    font-weight: 400;
}

.dynamicForm {
    margin-top: 16px;
    margin-bottom: 16px;
}