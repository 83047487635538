.dealBox {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    max-width: lg;
    padding: 3;
    background-color: var(--background-paper);
    border-radius: 2px;
    box-shadow: 24;
    transform: translate(-50%, -50%);
    overflow-y: auto;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.imageBox {
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
}

.img {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 4px;
}

.avatar {
    margin-right: 2rem;
}

.editButton {
    margin-right: 8px;
    color: #00C5B9 !important;
}