.buttonStyle {
  background-color: #197ba0 !important ;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.buttonStyleOutlined {
  color: #197ba0 ;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
