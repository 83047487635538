.card {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.cardMedia {
    border-bottom: 2px solid #f0f0f0;
}

.cardMediaMain {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 16px;
    border: 2px solid #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h4,
h6 {
    font-weight: bold;
    margin-bottom: 8px;
}

h4 {
    color: #333;
}

h6 {
    color: #555;
}

body {
    background-color: #f9f9f9;
}

.gallery {
    display: flex;
    justify-content: center;
    align-items: center;
}

.addressContainer {
    background-color: #fafafa;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.link {
    color: #1a73e8;
    text-decoration: none;
    transition: color 0.2s;
}

.link:hover {
    color: #0056b3;
}

.timingCard {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.timingCardContent {
    padding: 16px;
}

.overviewSection {
    margin-top: 20px;
    padding: 16px;
    background-color: #f0f8ff;
    border-radius: 8px;
}